import React from "react";
import { NavbarBrand, Container, Row, Col } from "reactstrap";
import { HashLink as Link } from "react-router-hash-link";
import ReactGA from "react-ga";
import "./SimpleFooter.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

class SimpleFooter extends React.Component {
  isMobile = window.innerWidth <= 580;
  columnSize = this.isMobile ? 1 : 6;

  reportGAEvent = (event) => {
    const target = event.target;
    ReactGA.event({
      category: "Client",
      action: target.attributes.value,
    });
  };

  render() {
    return (
      <>
        <footer className="footer">
          <Container>
            <Row className="row-grid align-items-center">
              {!this.isMobile && (
                  <NavbarBrand
                    className="nav-brand"
                    smooth
                    to="#top"
                    tag={Link}
                  >
                    <img
                      alt="damasio drywall logo"
                      className="nav-logo"
                      src={require("../../assets/img/logo/rv-logo-blk.png")}
                    />
                  </NavbarBrand>
              )}
              <Col>
                <Row>
                  <Col sm="4" className="info-column">
                    <Row>
                      <Col>
                        <h4 className="text-warning info-title">Location</h4>
                      </Col>
                    </Row>
                    {!this.isMobile && <hr className="info-separator" />}
                    <Row>
                      <Col>
                        <p className="text-dark footer-text mb-0">
                          4000 Hwy 7 <br />
                          Woodbridge, ON L4L 0C7 <br />
                          P.O.B. 30035
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="4" className="info-column contact-col">
                    <Row>
                      <Col>
                        <h4 className="text-warning info-title">Contact</h4>
                      </Col>
                    </Row>

                    {!this.isMobile && <hr className="info-separator" />}
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <div>
                              <h6>Phone</h6>
                              <p className="text-dark footer-text">
                                (647) 535-3560
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h6>E-mail</h6>
                            <a
                              className="link-text"
                              href="mailto:info@rvaluefoam.ca"
                              value="Clicked e-mail"
                              onClick={this.reportGAEvent}
                            >
                              info@rvaluefoam.ca
                            </a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="4" className="info-column follow-col">
                    <Row>
                      <Col>
                        <h4 className="text-warning info-title">Follow Us</h4>
                      </Col>
                    </Row>

                    {!this.isMobile && <hr className="info-separator" />}
                    <Row>
                      {!this.isMobile && (
                        <Col className="icon-col">
                          <Row>
                            <Col>
                              <a
                                className="icon-wrapper"
                                href="https://www.linkedin.com/company/damasio-drywall/"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <FontAwesomeIcon
                                  className="fa-icon"
                                  icon={faLinkedin}
                                />
                              </a>
                            </Col>
                            <Col>
                              <a
                                className="icon-wrapper"
                                href="https://www.facebook.com/damasiodrywall"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <FontAwesomeIcon
                                  className="fa-icon"
                                  icon={faFacebookSquare}
                                />
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <a
                                className="icon-wrapper"
                                href="https://www.instagram.com/damasiodrywall/"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <FontAwesomeIcon
                                  className="fa-icon"
                                  icon={faInstagram}
                                />
                              </a>
                            </Col>
                            <Col>
                              <a
                                className="icon-wrapper"
                                href="https://www.youtube.com/@DamasioDrywall"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <FontAwesomeIcon
                                  className="fa-icon"
                                  icon={faYoutube}
                                />
                              </a>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {this.isMobile && (
                        <Col className="icon-col">
                          <a
                            className="icon-wrapper"
                            href="https://www.linkedin.com/company/damasio-drywall/"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <FontAwesomeIcon
                              className="fa-icon"
                              icon={faLinkedin}
                            />
                          </a>
                          <a
                            className="icon-wrapper"
                            href="https://www.facebook.com/damasiodrywall"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <FontAwesomeIcon
                              className="fa-icon"
                              icon={faFacebookSquare}
                            />
                          </a>
                          <a
                            className="icon-wrapper"
                            href="https://www.instagram.com/damasiodrywall/"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <FontAwesomeIcon
                              className="fa-icon"
                              icon={faInstagram}
                            />
                          </a>
                          <a
                            className="icon-wrapper"
                            href="https://www.youtube.com/@DamasioDrywall"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <FontAwesomeIcon
                              className="fa-icon"
                              icon={faYoutube}
                            />
                          </a>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className="footer-separator" />
            <Row>
              <Col>
                <div className="copyright text-dark text-center">
                  © {new Date().getFullYear()} R-Value Spray Foam
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        ;
      </>
    );
  }
}

export default SimpleFooter;
